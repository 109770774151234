<template>
  <div class="container">
    <VueLoading v-model:active="state.isLoading" color="#196bb0"></VueLoading>
    <div class="mt-3 d-flex">
      <Pagination
        :current-page="page"
        :total-page="calTotalPage"
        @change-page="changePage"
        class="me-auto"
      ></Pagination>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#addProjectModal"
      >
        <font-awesome-icon :icon="['fas', 'plus-square']" class="nav-icon" />
        新增計畫
      </button>
    </div>
    <div class="row mt-3">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>編號</th>
            <th>計畫名稱</th>
            <th>選項</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(project, index) in activeProjects" :key="'project_' + index">
            <td class="align-middle col-1">{{ paged * (page - 1) + index + 1 }}</td>
            <td class="align-middle">{{ project.name }}</td>
            <td class="align-middle col-2">
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click.prevent="redirectToProject(project.id)"
                >
                  <font-awesome-icon :icon="['fas', 'edit']" class="nav-icon" />
                  詳細
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click.prevent="useDeleteProject(project.id, project.name)"
                  v-if="project.isAdmin"
                >
                  <font-awesome-icon :icon="['fas', 'trash-alt']" class="nav-icon" />
                  刪除
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 新增計畫 Modal -->
    <div
      class="modal fade"
      id="addProjectModal"
      ref="addProjectModal"
      tabindex="-1"
      aria-labelledby="addProjectModal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addProjectModal">新增計畫</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <VeeForm @submit="useAddProject" id="addProjectForm">
              計畫名稱：<Field name="projectName" :rules="isRequired" />
              <ErrorMessage name="projectName" class="ms-1 text-danger" />
            </VeeForm>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
            <button type="submit" class="btn btn-primary" form="addProjectForm">
              新增
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 載入 Vue 3 composition api
import {
  ref, toRefs, computed, onMounted,
} from 'vue';
// 載入 vue router
import { useRouter } from 'vue-router';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import { Modal } from 'bootstrap';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';
import Pagination from '@/components/Pagination.vue';
import useUserProjects from '@/composables/useUserProjects';

export default {
  components: {
    Pagination,
    VueLoading,
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    userUid: String,
    userEmail: String,
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    const router = useRouter();
    // ================================
    const { userUid } = toRefs(props);
    const {
      projects,
      state,
      getProjects,
      addProject,
      deleteProject,
    } = useUserProjects(userUid.value);
    // 操作 Modal
    const addProjectModal = ref(null);
    let bsModal = null;

    function useAddProject(values, { resetForm }) {
      addProject(values, () => {
        bsModal.hide();
        resetForm();
        if (state.isError === true) {
          state.isError = false;
          $swal.fire({
            text: String(state.errorMessage),
            icon: 'error',
          });
        }
      });
    }
    function useDeleteProject(id, name) {
      $swal
        .fire({
          text: `您確定要刪除"${name}"嗎？`,
          icon: 'warning',
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteProject(id, () => {
              if (state.isError === true) {
                state.isError = false;
                $swal.fire({
                  text: String(state.errorMessage),
                  icon: 'error',
                });
              }
            });
          }
        });
    }
    function redirectToProject(id) {
      router.push(`/project/${id}`);
    }
    // =================================================================
    const page = ref(1); // 記錄當前頁數
    const paged = ref(15); // 一頁顯示的筆數
    const calTotalPage = computed(() => Math.ceil(projects.value.length / paged.value));
    const activeProjects = computed(() => {
      const displayProjects = [];
      if (projects.value.length > 0) {
        projects.value.forEach((project, index) => {
          if (index < paged.value * page.value && index >= paged.value * (page.value - 1)) {
            displayProjects.push(project);
          }
        });
      }
      return displayProjects;
    });
    function changePage(targetPage) {
      page.value = targetPage;
    }
    // =================================================================
    function getDateString(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minute < 10) {
        minute = `0${minute}`;
      }
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    }
    function isRequired(value) {
      return value ? true : '此為必填項目';
    }
    // =================================================================

    onMounted(() => {
      // 讀取所有使用者能檢視之計畫案
      getProjects();
      // 元素建立後，製作 Modal Object 來控制開關
      bsModal = new Modal(addProjectModal.value);
    });

    return {
      // 計畫相關
      projects,
      getProjects,
      redirectToProject,
      useAddProject,
      useDeleteProject,
      state,
      // 分頁相關
      page,
      paged,
      calTotalPage,
      changePage,
      activeProjects,
      // 工具相關
      getDateString,
      isRequired,
      // Modal
      addProjectModal,
    };
  },
};
</script>

<style lang="scss" scoped>
thead {
  text-align: center;
  background-color: $primary;
  color: white;
}
tbody {
  text-align: center;
  color: $primary;
  tr:hover td{
    color: $primary;
    background-color: rgba($primary, 0.05);
  }
}
</style>
